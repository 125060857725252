<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "TepGraficaEncuestaPasajero",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          backGroundColor: "#ff1c79",
          type: "area",
        },
        title: {
          text: "Encuestas Conductores",
        },
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        legend: {
          layout: "vertical",
          align: "left",
          verticalAlign: "top",
          x: 70,
          y: 50,
          floating: true,
          borderWidth: 1,
        },
        xAxis: {
          categories: [],
          title: {
            text: "Preguntas",
          },
        },
        yAxis: {
          title: {
            text: "Cantidad",
          },
        },

        plotOptions: {
          area: {
            fillOpacity: 0.7,
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },

        exporting: {
          enabled: true,
        },
        series: [
          {
            data: [],
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      let filter = this.$parent.filters;
      filter.tipo_presupuesto = 1;
      await axios
        .get("/api/tep/dashboardTepNacionales/encuesta", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaEncuestaConductores(me.respuesta);
        });
    },

    async graficaEncuestaConductores(resp) {
      this.chartOptions.xAxis.categories = resp.categorias;
      this.chartOptions.series = resp.series;
      this.chartOptions.series.marker = {
        lineWidth: 15,
        fillColor: "black",
      };
    },
  },
};
</script>
